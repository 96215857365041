.header-navbar-item {
  margin: 10px;
  .nav-link {
    color: black;
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: bold;
    padding: 10px 20px;
    &:hover {
      background-color: #d9271b;
      color: white;
      border-radius: 5px;
    }
  }
  .active {
    background-color: #d9271b;
    color: white !important;
    border-radius: 5px;
  }
}

.header-slider-image {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

.logo {
  width: 100%;
}

.main-header {
  .logo-text {
    font-size: 37px;
    font-family: "Dudek Bold";
  }
  a {
    text-decoration: none;
    color: #123d6a;
    &:hover {
      color: #226ab6;
    }
  }
}
