.footer-main {
  background-color: #123d6a;
  margin-top: 30px;
  color: #a9a9a9;
}

.footer-second {
  background-color: #292a2c;
  .bottom-text {
    color: white;
    padding: 25px 0;
    text-align: center;
  }
}

.footer-navbar-item {
  margin: 15px;
  margin-right: 0;
  .nav-link-footer {
    color: black;
    font-family: "Dudek";
    font-size: 17px;
    padding: 9px 18px;
    text-decoration: none;
    background-color: #bbbbbb;
    &:hover {
      background-color: #ff0000;
      color: white;
      border-radius: 2px;
    }
  }
  .active-footer {
    background-color: #ff0000;
    color: white !important;
    border-radius: 2px;
  }
}
