.home-bottom-title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

// .divider-home {
//   position: relative;
//   height: 1px;
//   background-color: gray;
// }

.home-card {
  background-color: #f8f8f8;
  width: 100%;
  height: auto;
  border-radius: 13px;
  padding: 30px;
  position: relative;
  transition: transform 0.3s ease-out;
  a {
    color: #444;
    text-decoration: none;
  }
  .card-icon {
    margin: 15px 0;
    width: 17%;
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .card-description {
    font-size: 15px;
    padding-bottom: 10px;
  }
  .card-read-more {
    font-size: 13px;
    text-decoration: underline;
  }
  &:hover {
    background-color: #d9271b;
    cursor: pointer;
    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.5);

    a {
      color: white;
    }
  }
}

.home-divider-new {
  border-bottom: 0.5px solid #979797;
  padding: 20px 0;
}

.home-main {
  border: 2px solid #123d6a;
  padding: 50px;
  border-radius: 50px;
  position: relative;
}

.home-main::before {
  content: "WELCOME TO NOOR AIR CONDITIONER";
  position: absolute;
  font-family: "Dudek Bold";
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 12px;
  color: #123d6a;
  font-size: 30px;
}

.home-description {
  text-align: justify;
  padding: 0 40px;
  font-size: 17px;
}

.services {
  font-size: 30px;
  margin: 10px 0;
  color: #123d6a;
  font-family: "Dudek Bold";
}
